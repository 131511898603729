const ADMIN = 1;
const SERVICE_PROVIDER = 2;
const AUDITOR = 3;
const CUSTOMER_CARE = 4;

const DASHBOARD = {
  component: "DashBoard",
  url: "/dashboard",
};

const PROFILE = {
  component: "Profile",
  url: "/profile",
};

const TICKETS = {
  component: "Tickets",
  url: "/tickets",
};

const SETTINGS = {
  component: "Settings",
  url: "/settings",
};

const LOGOUT = {
  component: "Logout",
  url: "/logout",
};

const COMMON_ROUTES = [
  { ...LOGOUT },
  { ...DASHBOARD },
  { ...PROFILE },
  { ...TICKETS },
  { ...SETTINGS },
];

const ADMIN_LOGIN = {
  component: "Admin",
  url: "/configurations",
};

const SUPER_ADMIN_ROUTES = [...COMMON_ROUTES, DASHBOARD, ADMIN_LOGIN];
const SUPER_USER_ROUTES = [...COMMON_ROUTES, DASHBOARD, ADMIN_LOGIN];

export {
  ADMIN,
  AUDITOR,
  CUSTOMER_CARE,
  SERVICE_PROVIDER,
  SUPER_ADMIN_ROUTES,
  SUPER_USER_ROUTES,
  COMMON_ROUTES,
  DASHBOARD,
};
