/* eslint-disable react/state-in-constructor */
/* eslint-disable import/extensions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Row, Col, Modal, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import AuthHeader from "../authentication/authHeader.jsx";
import auth0Client from "../../auth0/Auth";
import mainLogo from "../../assets/images/exper_logo1.png";
import Styles from "../../styles/auth.module.css";

import "./forget.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const msg =
  "We've just sent you an email to reset your password." ||
  "Reset Password link has been sent to your email";

class ForgetPassword extends Component {
  state = {
    showMessage: false,
    loadingBtn: false,
    responseMessage: null,
  };

  componentDidMount() {}

  handleSubmit = async (e) => {
    try {
      const { emailId } = e;
      await this.setState({ loadingBtn: true });
      axios
        .post(`login?emailId=${emailId}&Password=${""}`)
        .catch(async (error) => {
          const { response = "" } = error || "";
          const { data: dt = "" } = response;
          const { message: msg1 } = dt;
          if (msg1 === "Email id does not exist") {
            message.error(`${msg1}`, 2);
            await this.setState({ loadingBtn: false });
          } else {
            const data = await auth0Client.forgotPassword(emailId);
            await this.setState({
              showMessage: true,
              responseMessage: data || msg,
              loadingBtn: false,
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  goToLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    const { showMessage, responseMessage, loadingBtn } = this.state;
    return (
      <div className={Styles.loginMainDiv}>
        <Modal
          visible={showMessage}
          title={<span className="fpTitle">Forgot Password Success</span>}
          centered
          maskClosable={false}
          destroyOnClose
          footer={[
            <Button key="submit" type="primary" onClick={this.goToLogin}>
              Go to login
            </Button>,
          ]}
        >
          <div className="fpSubTitle">{responseMessage}</div>
        </Modal>
        <AuthHeader />
        <Row className={[Styles.row, Styles.row1]}>
          <Col span={12} className={Styles.bgLoginImage} />
          <Col span={12} className={Styles.loginArea} style={{ padding: 0 }}>
            <div
              // className={`frgtPswdCss ${Styles.loginForm}`}
              className={[Styles.loginForm, Styles.frgtPswdCss].join(" ")}
              // style={{
              //   width: "100%",
              //   padding: 20,
              //   height: 500,
              //   background: "linear-gradient(to right, #bdc3c7,#2c3e50)",
              // }}
            >
              <div className="centering">
                <img
                  className={Styles.companyLogo}
                  src={mainLogo}
                  style={{ width: 100 }}
                  alt="MainLogo"
                />
              </div>
              <label className={Styles.mainLabelLogin}>Reset Password</label>
              <div className={Styles.subSpan}>
                <span>We’ll send you an email </span>
                <span>with instruction to reset it.</span>
              </div>
              <Form
                name="normal_login"
                layout="vertical"
                requiredMark={"optional"}
                initialValues={{
                  remember: true,
                }}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name="emailId"
                  label="Email "
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="Please enter your email address" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={Styles.loginFormButton}
                    style={{ marginTop: "15px" }}
                  >
                    {loadingBtn ? (
                      <Spin
                        indicator={antIcon}
                        size="large"
                        style={{ fontSize: 20 }}
                      />
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                  <div className={Styles.lastLabel}>
                    Already have an account?
                    <Link to="/login">Sign In</Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ForgetPassword;
