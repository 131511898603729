/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import auth0 from "auth0-js";
import axios from "axios";
import configuration from "./auth_config.json";

const { origin } = window.location;

const { domain, clientID, state, databaseConnection } = configuration;

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri: `${origin}/login`,
      responseType: state,
    });
  }

  manualLogin = (username, password) => {
    return new Promise((resolve, reject) => {
      this.auth0.login({ realm: databaseConnection, username, password }, (err, authResult) => {
        if (err) reject(err ? err.description : null);
        resolve(authResult);
      });
    }).catch(err => {
      console.error(err, "Something went wrong");
      return err ? err.description : null;
    });
  };

  forgotPassword = email => {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword({ connection: databaseConnection, email }, (err, resp) => {
        if (err) reject(err.description);
        resolve(resp);
      });
    }).catch(err => {
      console.error(err, "Something went wrong");
      return err ? err.description : null;
    });
  };

  httpChangePassword = async email => {
    const response = await axios.post(`https://${domain}/dbconnections/change_password`, {
      client_id: clientID,
      email,
      connection: databaseConnection,
    });
    return response;
  };

  signOut() {
    this.auth0.logout({ returnTo: origin, clientID });
  }
}

const auth0Client = new Auth();

export default auth0Client;
