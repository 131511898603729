const NAV = {
  cx: [
    { navTitle: "Screen 1", route: "screen1" },
    { navTitle: "Screen 2", route: "screen2" },
    { navTitle: "Screen 3", route: "screen3" },
    { navTitle: "Screen 4", route: "screen4" },
    { navTitle: "Screen 5", route: "screen5" },
  ],
  churn: [
    { navTitle: "Screen A", route: "screenA" },
    { navTitle: "Screen B", route: "screenB" },
    { navTitle: "Screen C", route: "screenC" },
    { navTitle: "Screen D", route: "screenD" },
    { navTitle: "Screen E", route: "screenE" },
  ],
};

export default NAV;
