/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React, { Component } from "react";
import ErrorHandler from "./error-handler";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    document.body.classList.add("d-iframe-none");
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo, hasError: true });
  }

  render() {
    const { errorInfo, error, hasError } = this.state;
    const { children, history } = this.props;
    if (error || errorInfo) {
      console.error({ componentError: error?.toString() });
      console.error({ componentErrorInfo: errorInfo?.componentStack });
    }
    if (hasError) {
      return <ErrorHandler errorCase="componentError" history={history} />;
    }

    return children;
  }
}

export default ErrorBoundary;
