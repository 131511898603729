import auth from "../utils/auth";

const { id: USER_ID } = auth.get("userDetails") || {};

const ORG_ID = Number(auth.get("selectedHDC"));

const ROLE_ID = auth.getRoleId() || null;

const tempUrl = "https://cors-anywhere.herokuapp.com/";

const DEV_IP =
  "https://exper.aq4.io/exper-api" || tempUrl + "http://35.184.171.85:5054";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const EXCEL_FILE_EXTENSION = ".xlsx";

const BASE_URL = `${DEV_IP}/api/`;

const DASHBOARD_PAGE_ROUTE = "/dashboard";

const ADMIN_PAGE_ROUTE = "/configurations";

const getToken = () => auth.get("apiToken");

export {
  EXCEL_FILE_TYPE,
  EXCEL_FILE_EXTENSION,
  BASE_URL,
  DASHBOARD_PAGE_ROUTE,
  ADMIN_PAGE_ROUTE,
  USER_ID,
  ORG_ID,
  ROLE_ID,
  getToken,
};
