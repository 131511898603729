/* eslint-disable no-param-reassign */
import * as axios from "axios";
import { getToken, BASE_URL } from "../constants/constants";

axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const token = getToken();

axios.defaults.baseURL = `${BASE_URL}`;

axios.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      if (token) {
        config.headers.Authorization = token;
        return Promise.resolve(config);
      }
    }
    return config;
  },
  error => Promise.reject(error),
);
