import React, { PureComponent } from "react";
import { Layout } from "antd";

import HeaderMenu from "../header/header";

import "./layout.css";

class MainLayout extends PureComponent {
  render() {
    return (
      <Layout>
        <Layout className="site-layout site-layout-background-bg">
          <HeaderMenu {...this.props} />
        </Layout>
      </Layout>
    );
  }
}

export default MainLayout;
