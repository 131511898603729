import { Table } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as RedDown } from "../../assets/images/redDown.svg";
import { ReactComponent as GreenUp } from "../../assets/images/greenUp.svg";

const columns = [
  {
    title: "Customer Name",
    dataIndex: "name"
  },
  {
    title: "",
    dataIndex: "address",
    render: (dt) => (
      <span style={{ color: "#0a6fb8", fontWeight: 500 }}>{dt}</span>
    )
  },
  {
    title: "Chunk Risk",
    dataIndex: "age",
    align: "center",
    render: (age) => (
      <div className="table-numeric">
        <span style={age > 40 ? { color: "#f25050" } : { color: "#82c250" }}>
          {age * 2} %
        </span>
        <span>
          {age > 40 && <Icon component={RedDown} />}
          {age < 40 && <Icon component={GreenUp} />}
        </span>
        <span style={age > 40 ? { color: "#f25050" } : { color: "#82c250" }}>
          {age} %
        </span>
      </div>
    )
  }
];

const data = [
  {
    key: "1",
    name: "Customer 1",
    age: 32,
    address: "Store | Region"
  },
  {
    key: "2",
    name: "Customer 2",
    age: 42,
    address: "Store | Region"
  },
  {
    key: "3",
    name: "Customer 3",
    age: 32,
    address: "Store | Region"
  }
];

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

export default function TableComponent() {
  return (
    <Table
      columns={columns}
      dataSource={[...data, ...data]}
      onChange={onChange}
      pagination={false}
    />
  );
}
