/* eslint-disable camelcase */
/* eslint-disable func-names */
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Auth = {
  set(name, authToken = null, options) {
    return cookies.set(name, authToken, options);
  },

  get(authToken) {
    const token = cookies.get(authToken) || null;
    return token;
  },

  remove(value) {
    return cookies.remove(value);
  },

  changeRole(roleId) {
    try {
      cookies.remove("selectedRole");
      this.set("selectedRole", roleId);
    } catch (err) {
      throw new Error({ error: err });
    }
  },

  getToken() {
    const token = this.get("token") || null;
    return token;
  },

  getRoleId() {
    const id = this.get("selectedRole");
    const roleId = id ? Number(id) : null;
    return roleId;
  },

  isAuthenticated() {
    const token = this.get("token") || null;
    const isValid = token !== null && token !== "";
    return isValid;
  },

  removeToken() {
    this.removeOrg();
    cookies.remove("userDetails");
    cookies.remove("allUserRoles");
    cookies.remove("selectedRole");
    cookies.remove("hdcWorkbench");
    cookies.remove("hdcWorkbenchCons");
    cookies.remove("selectedHDC");
    cookies.remove("selectedStore");
    cookies.remove("selectedGrower");
    this.deleteAllCookies();
    cookies.remove("token", { path: "/" });
    cookies.remove("apiToken", { path: "/" });
    return cookies.remove("token", { path: "/" });
  },

  storeSessionDetails(details = "") {
    this.removeToken();
    console.log(details);
    try {
      const {
        roleId: rId,
        tokenObject = "",
        tokenExpiresInSec: et,
        id,
        organisationId,
        userName,
        email,
        apiTokenObject = "",
      } = details;

      const { id_token, expires_in = 86400, token_type: tp } = tokenObject;

      const newToken = tp + " " + id_token;
      const time = et || expires_in;

      // const { access_token = "", token_type: tp2 = "" } = apiTokenObject;
      // const apiToken = `${tp2} ${access_token}`;
      const eTime = time && typeof time === "number" ? time * 1000 : 0;
      const expires = { path: "/", expires: new Date(Date.now() + eTime) };

      this.set("userDetails", {
        id,
        organisationId,
        userName,
        email,
      });
      this.set("selectedRole", Number(rId));
      this.set("token", newToken, expires);
      this.set("apiToken", newToken, expires);
    } catch (error) {
      console.log({ error });
      throw new Error({ error });
    }
  },

  getSessionDetails() {
    const userDetails = this.get("userDetails") || {};
    const userRoles = this.get("allUserRoles") || [];
    const selectedRole = this.getRoleId();
    const roleId = selectedRole;
    const optionalRoles = userRoles || [];
    return { userDetails, userRoles, roleId, optionalRoles, selectedRole };
  },

  removeOrg() {
    cookies.remove("selectedHDC");
    cookies.remove("selectedStore");
    cookies.remove("selectedGrower");
    return "";
  },

  deleteAllCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        ?.replace(/^ +/, "")
        ?.replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
  },
};

export default Auth;
