/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../auth";
import ErrorBoundaries from "../../pages/error-handling/error-boundaries";
import Layout from "../../components/layout/layout";
import Loader from "../../components/hoc/loader";
import ScrollToTop from "../../components/hoc/scroll";
import Footer from "../../components/footer/footer";

const SecuredRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.isAuthenticated()) {
        return (
          <ErrorBoundaries>
            <Layout component={Component} {...props} />
            <Suspense fallback={<Loader />}>
              <div className="app-container">
                <Component {...props} />
              </div>
            </Suspense>
            <ScrollToTop />
            <Footer />
          </ErrorBoundaries>
        );
      }
      return <Redirect to="/login" />;
    }}
  />
);

export default SecuredRoute;
