import React, { memo } from "react";
import { BackTop } from "antd";
import { UpSquareFilled } from "@ant-design/icons";

const ScrollToTop = () => {
  return (
    <BackTop style={{ right: 0, bottom: 30 }}>
      <UpSquareFilled style={{ fontSize: 24, color: "#406ae2" }} />
    </BackTop>
  );
};

export default memo(ScrollToTop);
