/* eslint-disable react/jsx-no-duplicate-props */
import React, { PureComponent } from "react";
import { Menu, Layout, Avatar } from "antd";
import {
  BellOutlined,
  SettingOutlined,
  DashboardOutlined,
  ProfileOutlined,
  ClockCircleOutlined,
  ToolOutlined
} from "@ant-design/icons";
import axios from "axios";

import auth0Client from "../../auth0/Auth";
import NAV from "../../constants/nav";
import auth from "../../utils/auth";
import logo from "../../assets/images/cxLogo.png";

import style from "./header.module.css";

const { Header } = Layout;
const { SubMenu } = Menu;

class HeaderMenu extends PureComponent {
  constructor(props) {
    super(props);
    const { match = "" } = props || "";
    const { path = "" } = match;
    const whichPageClicked = path.split("/")[1] || "dashboard";
    this.state = { whichPageClicked, moduleSelected: "cx", screens: [] };
  }

  componentDidMount() {
    this.getAllModules();
  }

  getAllModules = async () => {
    try {
      const response = await axios.get(`getUserModuleMapping?userId=14`);
      const { userModuleDTOS } = response?.data || "";
      this.getModuleMenu(userModuleDTOS);
      return "";
    } catch (err) {
      this.getModuleMenu();
      return "";
    }
  };

  getModuleMenu = (arr = []) => {
    const userModuleDTOS =
      arr.length > 0
        ? arr
        : [
            { moduleName: "Churn", moduleId: 1 },
            { moduleName: "Customer Experience", moduleId: 2 }
          ];

    const modulesArray = userModuleDTOS.map(({ moduleName, moduleId }) => (
      <Menu.Item
        key={moduleName}
        value={moduleId}
        onClick={(e) => this.moduleChange(e)}
      >
        {moduleName}
      </Menu.Item>
    ));
    this.setState(
      {
        modulesArray,
        moduleSelected: userModuleDTOS[0]?.moduleName || "Churn"
      },
      this.getScreenMenu
    );
  };

  getScreenMenu = () => {
    const { moduleSelected } = this.state;
    const { cx, churn } = NAV;
    const navArray = moduleSelected === "Churn" ? churn : cx;
    const screens = navArray.map(({ navTitle, route }) => (
      <Menu.Item
        key={route}
        onClick={() => this.props.history.push(`/${"dashboard" || route}`)}
      >
        {navTitle}
      </Menu.Item>
    ));
    this.setState({ screens });
  };

  logout = (e = "") => {
    if (e.key === "logout") {
      auth.removeToken();
      if (auth.getToken()) {
        auth.removeToken();
      }
      auth0Client.signOut();
      this.props.history.replace("/login");
    }
  };

  navigateTo = (gotTo = "dashboard") => {
    this.setState(
      { whichPageClicked: gotTo },
      this.props.history.replace(`/${gotTo}`)
    );
  };

  getActiveStyle = (path = "dashboard", via = 1) => {
    const { whichPageClicked = "dashboard" } = this.state;
    const iconColor = via === 1 ? {} : { color: "#fff" };
    const style = whichPageClicked === path ? { color: "orange" } : iconColor;
    return style;
  };

  moduleChange = ({ key = "Churn" }) =>
    this.setState({ moduleSelected: key }, this.getScreenMenu);

  render() {
    const { navigateTo, getActiveStyle, state } = this;
    const { screens, modulesArray } = state;
    return (
      <Header
        className="site-layout-background"
        style={{
          padding: 0,
          position: "fixed",
          zIndex: 10000000000000000000,
          width: "100%"
        }}
        className={style.menuHeader}
      >
        <Menu
          size="small"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="horizontal"
          className={style.leftMenu}
        >
          <SubMenu
            key="logoApp"
            icon={
              <img
                src={logo}
                alt="logo"
                style={{ width: 100, height: 40, background: "#f7fafc" }}
              />
            }
            title=""
            onTitleClick={() => navigateTo("dashboard")}
          />
        </Menu>

        <Menu
          size="small"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="horizontal"
          className={style.leftMenu}
        >
          <SubMenu
            style={getActiveStyle("dashboard", 1)}
            key="DashBoard"
            icon={
              <DashboardOutlined
                style={({ color: "#353333" }, getActiveStyle("dashboard", 2))}
              />
            }
            title="DashBoard"
            onTitleClick={() => navigateTo("dashboard")}
          >
            {screens}
          </SubMenu>

          <SubMenu
            style={getActiveStyle("profile", 1)}
            key="Profile"
            icon={<ProfileOutlined style={getActiveStyle("profile", 2)} />}
            title="Profile"
            onTitleClick={() => navigateTo("profile")}
          />
          <SubMenu
            style={getActiveStyle("tickets", 1)}
            key="Tickets"
            icon={<ClockCircleOutlined style={getActiveStyle("tickets", 2)} />}
            title="Tickets"
            onTitleClick={() => navigateTo("tickets")}
          />
          <SubMenu
            style={getActiveStyle("settings", 1)}
            key="Settings"
            icon={<ToolOutlined style={getActiveStyle("settings", 2)} />}
            title="Settings"
            onTitleClick={() => navigateTo("settings")}
          />
          <SubMenu
            key="Notification"
            icon={<BellOutlined style={{ color: "#353333" }} />}
            title="Notification"
          />

          <SubMenu
            key="sub2"
            icon={<SettingOutlined style={{ color: "#353333" }} />}
            title="Modules"
          >
            {modulesArray}
          </SubMenu>

          <SubMenu
            key="2"
            icon={
              <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
                U
              </Avatar>
            }
          >
            <Menu.Item key="7">Account Settings</Menu.Item>
            <Menu.Item key="logout" onClick={(e) => this.logout(e)}>
              Sign Out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    );
  }
}

export default HeaderMenu;
