import { Layout, Row, Col, Tabs } from "antd";
import Header from "../../components/header/header";
import SubMenu from "../../components/submenu";
import Cards from "./cards";
import Table from "./table";
import Chart from "./charts";

import "./layout.css";

const { Content, Footer } = Layout;
const { TabPane } = Tabs;

const LayoutComponent = () => {
  return (
    <Layout className="layout">
      <Header />
      <div className="logo" />

      <Content>
        <SubMenu />
        <div className="site-layout-content">
          <Row>
            <Col span={12}>
              <Cards />
              <Chart />
            </Col>
            <Col span={11} offset={1}>
              <Tabs type="card">
                <TabPane tab="Highest Flight Risk" key="1">
                  <div className="tableWrapper">
                    <Table />
                  </div>
                </TabPane>
                <TabPane tab="Past Flight Risk" key="2">
                  Content of Tab Pane 2
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>2021 © 360CX.ai</Footer>
    </Layout>
  );
};

export default LayoutComponent;
