import moment from "moment";

import { AUDITOR, CUSTOMER_CARE, SERVICE_PROVIDER, ADMIN } from "../../constants/role-constants";

const setDateOnLoad = () => {
  const dateObj = moment();
  const todaydate = dateObj.format("DD-MM-YYYY");
  return todaydate;
};

const TODAY_DATE = setDateOnLoad();

const fetchNavigation = roleId => {
  const id = roleId ? Number(roleId) : null;
  switch (id) {
    case ADMIN:
      return "/dashboard";
    case AUDITOR:
    case SERVICE_PROVIDER:
    case CUSTOMER_CARE:
      return "/dashboard";
    default:
      return "/dashboard";
  }
};

const getColor = opt => {
  if (opt === "Not Scheduled") {
    return ["#e6e6e6", "#43506b"];
  }
  if (opt === "Active") {
    return ["#e4d4e4", "#313232"];
  }
  if (opt === "In Transit") {
    return ["#ffec8d", "#5a4b00"];
  }
  if (opt === "Complete") {
    return ["#c2ff46", "#297b00"];
  }
  if (opt === "Scheduled") {
    return ["#42f7ff", "#00595d"];
  }
  if (opt === "Packed") {
    return ["#a8ffbd", "#00701b"];
  }
  if (opt === "Putaway") {
    return ["#d4aef7", "#4e0097"];
  }
  if (opt === "Ready") {
    return ["#a7b8ff", "#021d91"];
  }
  if (opt === "On Hold") {
    return ["#f7aecb", "#630128"];
  }
  if (opt === "Received") {
    return ["#f7caae", "#521f00"];
  }
  if (opt === "Picked") {
    return ["#ccc389", "#313232"];
  }
  return ["#e6e6e6", "#43506b"];
};

export { setDateOnLoad, getColor, fetchNavigation, TODAY_DATE };
