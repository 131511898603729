/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/extensions */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { Row, Col, Spin, message } from "antd";

import axios from "axios";
import LoginForm from "./loginForm";
import auth from "../../utils/auth";
import { fetchNavigation } from "../common-functions/reusable-function";
import CXLogo from "../../assets/images/cxLogo.png";
import Styles from "../../styles/auth.module.css";

const navigation = window.location;

const { search, origin } = navigation;

const isLoading = search && search !== "" && search.includes("code=");

class Login extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;
    this.isAuthenticated = auth.isAuthenticated();
  }

  componentDidMount() {
    if (this.isAuthenticated) {
      const roleId = auth.getRoleId();
      const navigateTO = fetchNavigation(roleId);
      this.history.push(navigateTO);
    }
    if (isLoading && !this.isAuthenticated) {
      this.generateAuthToken();
    }
  }

  generateAuthToken = () => {
    try {
      const code = search.substring(
        search.lastIndexOf("code="),
        search.lastIndexOf("&state")
      );
      const apiUrl = `getUserLoginInfo?${code}&redirectURI=${origin}/login`;
      axios
        .get(apiUrl)
        .then((response) => {
          console.log({ response });
          const { userDTO = "" } = response?.data || "";
          const { userRolesDTOS = [] } = userDTO;
          const { roleId } = Array.isArray(userRolesDTOS)
            ? userRolesDTOS[0]
            : "";
          const navigateTO = fetchNavigation(roleId);
          auth.storeSessionDetails({ ...userDTO, roleId });
          message.success(`Welcome`, 2);
          navigation.replace(navigateTO);
        })
        .catch((error = "") => {
          const { response = "" } = error;
          const { data = "" } = response;
          const { message: msg = "" } = data;
          message.error(`${msg}`, 2);
          // navigation.replace("/login");
          console.log({ error });
        });
    } catch (error) {
      message.error("Something went wrong");
      throw new Error({ error });
    }
  };

  render() {
    if (isLoading || this.isAuthenticated) {
      return (
        <div className="loader-login">
          <Spin tip="Loading..." />
        </div>
      );
    }
    return (
      <div className={Styles.loginMainDiv}>
        <Row className={[Styles.row, Styles.row1]}>
          <Col span={9} className={Styles.bgLoginImage}>
            <div className={Styles.logoZone}>
              <img src={CXLogo} alt="logo" />
            </div>
          </Col>
          <Col span={15} className={Styles.loginArea} style={{ padding: 0 }}>
            <LoginForm {...this.props} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Login;
