import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./pages/login";
import ForgetPassword from "./pages/forget-password";
import Register from "./pages/register";
import Layout from "./pages/layout";
import NotFound from "./pages/error-handling/not-found";
import SecuredRoute from "./utils/SecuredRoute/SecuredRoute";
import getAllUserRoutes from "./utils/SecuredRoute/role";
import * as RoutingPages from "./utils/SecuredRoute/index";
import auth from "./utils/auth";
import createBrowserHistory from "./utils/history";

import "./App.css";

const history = createBrowserHistory();

const getSecuredRoutes = (roles) => {
  if (roles && Array.isArray(roles) && roles.length > 0) {
    const privateRoutes = roles.map(({ url, component }) => {
      return (
        <SecuredRoute
          path={url}
          component={RoutingPages[component]}
          key={url}
        />
      );
    });
    return privateRoutes;
  }
  return [];
};

function App() {
  const roles = getAllUserRoutes();
  const privateRoutes = getSecuredRoutes(roles);
  const { pathname } = window.location;
  const notFoundComponet = <Route path="/*" component={NotFound} />;
  const redirectToLogin = <Redirect to="/login" />;

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/register" component={Register} />
        <Route path="/layout" component={Layout} />
        {pathname === "/" && (
          <SecuredRoute path="/" component={RoutingPages.DashBoard} />
        )}
        {privateRoutes}
        {auth.isAuthenticated() ? notFoundComponet : redirectToLogin}
      </Switch>
    </Router>
  );
}

export default App;
