import React from "react";
import { Row, Select, Slider } from "antd";
import AnyChart from "anychart-react";

const data = [
  { x: 4, value: 42 },
  { x: 13, value: 59 },
  { x: 25, value: 68 },
  { x: 25, value: 63 },
  { x: 44, value: 54 },
  { x: 55, value: 58 },
  { x: 56, value: 46 },
  { x: 60, value: 54 },
  { x: 72, value: 73 }
];

const complexSettings = {
  height: 250,
  type: "quadrant",
  data,
  title: ""
};

const marksVertical = {
  0: {
    style: {
      color: "#fff"
    },
    label: <span>Low</span>
  },
  100: {
    style: {
      color: "#fff"
    },
    label: <span>High</span>
  }
};

const marks = {
  0: {
    style: {
      color: "#fff"
    },
    label: <span>£ 1k</span>
  },
  50: {
    style: {
      color: "#fff"
    },
    label: <span>Customer Segment</span>
  },
  100: {
    style: {
      color: "#fff"
    },
    label: <span>£ 10Mn</span>
  }
};

export default function Charts() {
  return (
    <div className="chart-class">
      <Row justify="space-between" align="middle">
        <div>
          <b>Likely Revenue Risk</b>
        </div>
        <div>
          <Select style={{ width: 150 }} placeholder="Color By" />
        </div>
      </Row>
      <div className="chart-div">
        <Row>
          <div className="vertical-slider">
            <Slider vertical defaultValue={30} marks={marksVertical} />
          </div>
          <AnyChart {...complexSettings} />
        </Row>
        <div className="horizontal-slider">
          <Slider defaultValue={90} marks={marks} />
        </div>
      </div>
    </div>
  );
}
