import { Card, Col, Row } from "antd";

const Cards = () => {
  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card
            style={{
              background: "#e9f6ff",
              borderColor: "#bae2fe",
              borderRadius: 5
            }}
          >
            <b>Revnue At-Risk</b>
            <div>
              <b>£118.24</b>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              background: "#fff8e7",
              borderColor: "#ed9c22",
              borderRadius: 5
            }}
          >
            <b>Highest Opportunities</b>
            <div>
              <b>£168.24</b>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            style={{
              background: "#e8fffc",
              borderColor: "#9edad2",
              borderRadius: 5
            }}
          >
            <b>Reduced Churn Risk</b>
            <div>
              <b>£108.24</b>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Cards;
