/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Alert, Spin, Row } from "antd";
import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import auth0Client from "../../auth0/Auth";
import auth from "../../utils/auth";
import Styles from "../../styles/auth.module.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#fff" }} spin />
);

const messageStyle = { textAlign: "center", fontWeight: 600 };

const layout = {
  labelCol: { span: 24 }
};

const LoginFooter = () => (
  <div className="login-footer">
    <LockOutlined style={{ fontSize: 16, color: "#8692a6" }} />
    <h4>Your Info is safely secured</h4>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loggingIn: false
    };
  }

  handleSubmit = async (e) => {
    const { emailId, password } = e;
    auth.removeToken();
    this.setState({ errorMessage: null, loggingIn: true });
    try {
      const response = await auth0Client.manualLogin(emailId, password);
      this.setState({
        errorMessage: response || "Invalid credentials",
        loggingIn: false
      });
    } catch (error) {
      this.setState({ loggingIn: false });
      throw new Error({ error });
    }
  };

  onChange = () => {
    this.setState({ errorMessage: null });
  };

  render() {
    const { errorMessage, loggingIn } = this.state;
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100%"
        }}
      >
        <div
          className={Styles.loginForm}
          style={{
            padding: "30px 45px"
          }}
        >
          <label className={Styles.mainLabelLogin}>Login to your account</label>
          {errorMessage && (
            <Alert message={errorMessage} type="error" style={messageStyle} />
          )}
          <Form
            initialValues={{
              remember: true
            }}
            {...layout}
            requiredMark={"optional"}
            name="login"
            onFinish={this.handleSubmit}
          >
            <Form.Item
              labelCol={{ span: 24 }}
              name="emailId"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter valid Email id"
                }
              ]}
              onChange={this.onChange}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24 }}
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!"
                }
              ]}
              onChange={this.onChange}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
                <Link className={Styles.loginFormForgot} to="/forget-password">
                  Reset password?
                </Link>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className={Styles.loginFormButton}
              >
                {loggingIn ? (
                  <Spin
                    indicator={antIcon}
                    size="large"
                    style={{ fontSize: 20 }}
                  />
                ) : (
                  "Sign In"
                )}
              </Button>
            </Form.Item>
          </Form>
          <LoginFooter />
          <Row justify="center" align="middle">
            <span className={Styles.registerRow}>
              Don’t have an account?
              <span className={Styles.register}> Register</span>
            </span>
          </Row>
        </div>
      </Row>
    );
  }
}

export default LoginForm;
