/* eslint-disable react/prop-types */
import React from "react";
import ErrorHandler from "./error-handler";

class NotFound extends React.PureComponent {
  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;
  }

  render() {
    return <ErrorHandler errorCase="pageNotFound" history={this.history} />;
  }
}

export default NotFound;
