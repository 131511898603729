/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
import React from "react";
import { Result, Button } from "antd";

class ErrorHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;
  }

  goToHome = () => this.history.push("/settings");

  chooseErrorHandler = error => {
    switch (error) {
      case "pageNotFound":
        return (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary" onClick={this.goToHome}>
                Go to home
              </Button>
            }
          />
        );

      case "componentError":
        return (
          <Result
            status="500"
            title="Something went wrong"
            subTitle="Sorry, there are some problems with your operation."
            extra={
              <>
                <Button type="primary" key="console" onClick={() => window.location.reload()}>
                  Reload
                </Button>
                <Button type="primary" onClick={() => window.location.replace("/settings")}>
                  Go to home
                </Button>
              </>
            }
          />
        );

      case "serverError":
        return (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, the server is wrong."
            extra={<Button type="primary">Back Home</Button>}
          />
        );

      default:
        break;
    }
  };

  render() {
    return <>{this.chooseErrorHandler(this.props.errorCase)}</>;
  }
}

export default ErrorHandler;
