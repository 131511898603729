import { Card, Row, Col, DatePicker, Input, Space } from "antd";
import Finning from "../../assets/images/finning.png";
import FilterClose from "../../assets/images/filterClose.png";

const { Search } = Input;

const SubMenu = () => {
  return (
    <Card className="card-submenu">
      <Row align="middle">
        <Col span={10}>
          <Space>
            <b>Dashboard</b>
            <img src={Finning} alt="Finning" />
            <b>360 CX Revenue Risk Summary</b>
          </Space>
        </Col>
        <Col span={14}>
          <Row justify="end">
            <Space>
              <DatePicker />
              <DatePicker />
              <img src={FilterClose} alt="FilterClose" />
              <Search
                placeholder="Search by Region"
                style={{ minWidth: 300 }}
              />
            </Space>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default SubMenu;
