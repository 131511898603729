/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Row, Col } from "antd";
import mainLogo from "../../assets/images/aq4_logo.png";
import Styles from "../../styles/auth.module.css";

const AuthHeader = () => {
  return (
    <div>
      <Row className={Styles.row}>
        <Col lg={14}>
          <div className={Styles.clogo}>
            <img className={Styles.companyLogo} src={mainLogo} alt="MainLogo" />
          </div>
        </Col>
        <Col span={10}>
          {/* <div className={Styles.label}>
            <label className={Styles.left} style={{ fontSize: 16 }}>
              For System Support please contact your on-site super User
            </label> */}
          {/* <label className={Styles.right}>For System Support please contact your on-site super User</label> */}
          {/* </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default AuthHeader;
