import auth from "../auth";
import {
  ADMIN,
  AUDITOR,
  CUSTOMER_CARE,
  SERVICE_PROVIDER,
  SUPER_ADMIN_ROUTES,
  SUPER_USER_ROUTES,
  COMMON_ROUTES,
} from "../../constants/role-constants";

const getAllUserRoutes = () => {
  const id = auth.getRoleId() || null;
  const rId = id ? Number(id) : null;
  switch (rId) {
    case AUDITOR:
      return SUPER_ADMIN_ROUTES;
    case CUSTOMER_CARE:
      return SUPER_USER_ROUTES;
    case SERVICE_PROVIDER:
      return SUPER_USER_ROUTES;
    case ADMIN:
      return SUPER_USER_ROUTES;
    default:
      return COMMON_ROUTES;
  }
};
export default getAllUserRoutes;
